<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Добавить стоимость доставки для региона</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="addDeliveryRegionForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Регион</label>
                <md-select v-model="deliveryCost.region" @focus="reset">
                  <md-option
                    v-for="item in regions"
                    :key="item.id"
                    :value="item.name"
                    placeholder="Регион"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Стоимость</label>
                <md-input
                  v-model="deliveryCost.cost"
                  type="number"
                  min="0"
                  @focus="reset"
                  @change="validateCost"
                />
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button
                class="md-raised md-success"
                @click="addNewDeliveryRegion"
              >
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

const initDeliveryCost = () => ({
  region: null,
  cost: null,
});

export default {
  data() {
    return {
      deliveryCost: initDeliveryCost(),
    };
  },

  computed: {
    ...mapGetters({ regions: "regions/sortedByName" }),
  },

  methods: {
    ...mapActions("deliveryCost", ["createDeliveryRegion"]),

    async addNewDeliveryRegion() {
      const isValid = await this.$refs.addDeliveryRegionForm.validate();
      if (!isValid) {
        return;
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.createDeliveryRegion(this.deliveryCost);
      if (success) {
        this.deliveryCost = initDeliveryCost();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    validateCost(event) {
      if (event.target.value < 0) {
        this.deliveryCost.cost = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
